@use '../abstracts/mixins' as *;

.outlet__cart-grid-desktop {
  padding-bottom: 0;
}

.outlet__cart-grid-header {
  display: grid;
  grid-template-columns: 2fr 4fr 2fr 2fr 2fr 1fr;
  grid-gap: 1.5rem;
  padding: 0.5rem;
  font-weight: 700;
  border-bottom: 2px solid var(--clr-gray);
}

.outlet__cart-grid-product {
  display: grid;
  grid-template-columns: 2fr 4fr 2fr 2fr 2fr 1fr;
  grid-gap: 1.5rem;
  align-items: center;
  padding: 0.5rem;

  border-bottom: 1px solid var(--clr-gray);
  background-color: var(--clr-gray-lightest);

  &:nth-child(2n) {
    background-color: white;
  }

  &:last-child {
    border-bottom: 2px solid var(--clr-gray);
  }
}

.outlet__cart-grid-total {
  display: grid;
  grid-template-columns: 13fr 1fr;
  justify-items: end;
  grid-gap: 1.5rem;
  padding: 0 0.5rem;
  font-weight: 700;
  margin-top: 1rem;
}

.outlet_cart-grid-total-label {
  margin-right: 1rem;
}

.outlet__item-delete-button {
  background-color: transparent;
  border-radius: 0.5rem;
  padding: 0.35rem;
  margin-top: 0.5rem;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:first-child {
    margin-top: 0;
  }

  & svg {
    width: 1.35rem;
    height: 1.35rem;
    object-fit: contain;
    object-position: center;

    & g {
      stroke-width: 5 !important;
      stroke: var(--clr-red-dark) !important;
    }
  }
}

.outlet__cart-grid-product-amount {
  & input {
    width: 80px;
  }
}

.outlet__cart-grid-header-name {
  justify-self: center;
}

.outlet__cart-grid-product-name {
  justify-self: center;

  & a {
    text-decoration: none;
  }
}

.outlet__cart-grid-product-image {
  justify-self: center;
}

.outlet__cart-grid-item-image {
  height: 50px;
  width: 50px;
  object-fit: contain;
  object-position: center;
}

.outlet__cart-grid-product-amount {
  justify-self: center;
}

.outlet__cart-grid-header-amount {
  justify-self: center;
}

.outlet__cart-grid-header-price {
  justify-self: end;
}

.outlet__cart-grid-product-price {
  justify-self: end;
}

.outlet__cart-grid-header-price-total {
  justify-self: end;
}

.outlet__cart-grid-product-price-total {
  justify-self: end;
}
