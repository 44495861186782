@use '../../abstracts/mixins' as *;
@use '../../utilities/container';

.container-field {
  padding: 2rem 1.5rem;
  background: var(--bg-color, transparent);
  display: inline-block;

  border-radius: 0.5rem;

  & > .container {
    margin: 0 auto;
  }

  & > .streamfield {
    border: 0;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    background: transparent;
  }

  & > .container section:last-child {
    padding: 1rem 0;
  }

  & section:last-child {
    padding: 0;
  }

  &--full-width {
    width: 100%;
  }

  &--blue {
    background-color: rgba(232, 244, 252, 0.4);
    color: var(--clr-gray-dark);
    border: 1px solid var(--clr-blue-light);
  }

  &--grey {
    background-color: white;
    border: 1px solid var(--clr-gray-light);
    color: var(--clr-gray-dark);
  }

  &--red {
    background-color: rgba(248, 215, 218, 0.3);
    color: var(--clr-gray-dark);
    border: 1px solid var(--clr-red-light);
  }

  &--yellow {
    background-color: rgba(255, 243, 205, 0.35);
    color: var(--clr-gray-dark);
    border: 1px solid var(--clr-yellow-light);
  }
}
