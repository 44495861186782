.menu-history__tab-directions {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.menu-history__headline {
  margin-bottom: 2.5rem;
}

.menu-history__date {
  margin-bottom: 1.5rem;
}
