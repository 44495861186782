@use '../abstracts/mixins' as *;

// .blackboard-preview {
//   padding: 1rem 0 2rem 0;
// }

.blackboard__container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 1.5rem;

  @include mq(small) {
    grid-template-columns: 1fr 1fr;
  }

  @include mq(medium) {
    grid-gap: 2rem;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.blackboard__container.dashboard {
  @include mq(medium) {
    grid-gap: 2rem;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.blackboard__element-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: var(--clr-white);
  border-radius: 0.5rem;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
  text-decoration: none;
  @include transition(all);
  position: relative;

  &:hover {
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.2);
  }
}

.blackboard__element {
  text-decoration: none;
}

.blackboard__image-container {
  width: 100%;
  height: 10rem;
  position: relative;

  & > span {
    // Overwriting default NextImage styles
    width: 100% !important;
    height: 100% !important;
  }
}

.blackboard__image {
  width: 100%;
  height: 100%;
  border-radius: 0.5rem 0.5rem 0 0;
  object-fit: cover;
  object-position: center;
  position: absolute;
  left: 0;
}

.blackboard__content {
  padding: 2rem 1.5rem 2rem 1.5rem;
  width: 100%;
  color: var(--clr-gray-dark);
  display: flex;
  position: relative;

  &.no-image {
    padding: 2rem 4.5rem 2rem 1.5rem;
  }
}

.blackboard__main {
  width: 100%;
}

.blackboard__headline {
  width: 100%;
}

.blackboard__description {
  font-size: var(--fs-400);
  margin-top: 0.75rem;
}

.blackboard__item-edit-buttons {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 20;
  top: 1rem;
  right: 1rem;
}

.blackboard__item-edit-button {
  background-color: white;
  border-radius: 0.5rem;
  padding: 0.5rem;
  margin-bottom: 0.75rem;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  & svg {
    width: 1.5rem;
    height: 1.5rem;
    object-fit: contain;
    object-position: center;

    & g {
      stroke-width: 5 !important;
    }
  }
  &.delete svg g {
    stroke: var(--clr-red-dark) !important;
  }
}

// Dashboard specific styles
.blackboard__element--dashboard {
  & .blackboard__content {
    padding: 1rem 1.5rem;
  }

  & .blackboard__image-container {
    height: 9rem;
  }

  & .blackboard__headline {
    font-size: var(--fs-500);
  }
}
