@use '../abstracts/mixins' as *;

.menu-listing__tab-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  @include mq(medium) {
    gap: 1.5rem;
  }
}

.menu-listing__tab-directions {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.menu__tab-direction-svg {
  stroke-width: 3;
}

.menu-listing__overview-starters {
  padding: 1.5rem 0;
  border-bottom: 1px solid var(--clr-gray-light);
}

.menu-listing__overview-main {
  padding: 1.5rem 0;
  border-bottom: 1px solid var(--clr-gray-light);
}

.menu-listing__overview-dessert {
  padding: 1.5rem 0;
  border-bottom: 1px solid var(--clr-gray-light);
}

.menu-listing__week-headline {
  margin-bottom: 2rem;
}

.menu-listing__category-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 2.5rem 6rem;

  @include mq(small) {
    grid-template-columns: 1fr 1fr;
  }

  & .checkbox__root {
    border-width: 2px;
  }
}

.menu-listing__menu-container {
  display: flex;
  margin: 1rem 0;
  flex-wrap: wrap;
  gap: 0 2rem;
  justify-content: space-between;

  & .checkbox {
    padding-bottom: 0;
  }
}

.menu-listing__menu-headline-wrapper {
  display: flex;
  gap: 0 2rem;
}

.menu-listing__menu-headline {
  color: var(--clr-gray-darkest);
  line-height: 1.5;
  hyphens: none;
}

.menu-listing__menu-price {
  font-weight: 700;
  font-size: var(--fs-400);
  flex-shrink: 0;
}

.menu-listing__menu-description {
  margin: 1rem 0;
}

.menu-listing__menu-options-wrapper {
  margin-top: 0;

  &:not(:first-child) {
    margin-top: 1rem;
  }
}

.menu-listing__menu-options-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 0 1rem;
  margin-top: 0.5rem;

  @include mq(medium) {
    grid-template-columns: 1fr 1fr;
    margin-top: 0;
  }
}

.menu-listing__menu-options-headline {
  margin: 1rem 0;
  font-size: var(--fs-400);
}

.menu-listing__buttons-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5rem;
  gap: 2rem;
  flex-wrap: wrap-reverse;
}

.menu-listing__order-buttons {
  display: flex;
  gap: 2rem 1.5rem;
  flex-wrap: wrap-reverse;
}

.menu-listing__menu-in-cart-svg {
  width: 24px;
  height: 24px;
  object-fit: contain;
  margin-right: 0.25rem;

  & g {
    stroke: var(--clr-green) !important;
    stroke-width: 4 !important;
  }
}

.menu-listing__menu-already-ordered-svg {
  width: 24px;
  height: 24px;
  object-fit: contain;
  margin-right: 0.25rem;

  & path {
    stroke: var(--clr-green) !important;
    stroke-width: 5 !important;
  }
}

.menu-listing__menu-status--ordered {
  color: var(--clr-green);
  display: flex;
  align-items: center;
}

.menu-listing__menu-status--cart {
  color: var(--clr-green);
  display: flex;
  align-items: center;
}

.menu-listing__menu-additives-container {
  display: flex;
  gap: 2rem;
  align-items: center;
  flex-wrap: wrap;

  & h4 {
    color: var(--clr-gray-darkest);
    min-width: 100px;
  }
}

.menu-listing__menu-additives-list {
  display: flex;
  gap: 0.5rem 1rem;
  flex-wrap: wrap;
  font-size: var(--fs-300);
}

.menu-listing__cart-icon {
  width: 28px;
  height: 28px;

  & g {
    stroke-width: 4 !important;
    stroke: var(--clr-white) !important;
  }
}

.menu-listing__all-buttons {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.menu-listing__tab-directions-cart {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  flex-grow: 1;

  @include mq(medium) {
    gap: 0.75rem;
  }
}

.button--cart {
  position: relative;
  background-color: var(--clr-blue);

  &:hover,
  &:focus {
    background-color: var(--clr-blue-dark);
  }
}

.button--cart__number {
  position: absolute;
  right: 0.75rem;
  top: 0.25rem;
  height: 14px;
  width: 14px;
  font-size: 0.5rem;
  font-weight: 700;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9999px;
  background-color: white;
}
