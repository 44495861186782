@use '../abstracts/mixins' as *;
@use '../utilities/container';

.menu-cart__grid-header {
  display: grid;
  grid-template-columns: 3fr 13fr;
  grid-gap: 0.5rem;
  font-weight: 700;
  border-bottom: 2px solid var(--clr-gray);
  padding: 1rem 1rem 0.5rem 1rem;
}

.menu-cart__grid-header-wrapper {
  display: grid;
  justify-content: space-between;
  grid-template-columns: 1fr 90px 60px 75px 30px;
  align-items: center;
  gap: 1rem;

  @include mq(large) {
    gap: 3rem;
  }
}

.menu-cart__grid-header-meal {
  flex-grow: 1;
}

.menu-cart__grid-header-price {
  display: flex;
  justify-content: flex-end;
}

.menu-cart__grid-day {
  display: grid;
  grid-template-columns: 3fr 13fr;
  padding: 1rem;
  grid-gap: 0.5rem;
  border-bottom: 1px solid var(--clr-gray);
  background-color: var(--clr-gray-lightest);

  &:nth-child(2n + 1) {
    background-color: white;
  }

  &:last-child {
    border-bottom: 2px solid var(--clr-gray);
  }
}

.menu-cart__grid-daily-price {
  font-weight: 700;
  margin-top: 0.5rem;
  display: flex;
  justify-content: flex-end;
  white-space: nowrap;
  margin-right: calc(30px + 1rem);

  @include mq(large) {
    margin-right: calc(30px + 3rem);
  }
}

.menu-cart__grid-day-date-name {
  margin-bottom: 0.5rem;
}

.menu-cart__grid-day-meal-name {
  display: flex;
  gap: 0 0.25rem;
  flex-wrap: wrap;
  flex-grow: 1;
}

.menu-cart__grid-day-meal-price {
  display: flex;
  justify-content: flex-end;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.menu-cart__grid-footer {
  display: flex;
  align-items: center;
  padding: 1rem 1rem 0 1rem;
  justify-content: flex-end;
  margin-right: calc(30px + 1rem);
  gap: 0.5rem;

  @include mq(large) {
    margin-right: calc(30px + 3rem);
  }
}

.menu-cart__grid-item {
  display: grid;
  justify-content: space-between;
  grid-template-columns: 1fr 90px 60px 75px 30px;
  align-items: center;
  margin-top: 0.5rem;
  gap: 1rem;

  @include mq(large) {
    gap: 3rem;
  }

  &:first-child {
    margin-top: 0;
  }
}

.menu-cart__grid-day-meal-amount .input {
  margin: 0;
}

.menu-cart__grid-day-meal-amount .input__element {
  height: 30px;
  padding: 0.25rem 0.75rem;
}

.menu-cart__grid-day-meal-packaged {
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-cart__grid-day-meal-packaged .checkbox {
  padding-bottom: 0;
}
