.layout {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.layout__content {
  width: 100%;
  flex-grow: 1;
  display: flex;
}

.layout__main {
  flex-grow: 1;
}

.section-headline {
  margin-bottom: 1.5rem;
  margin-left: 1.5rem;
  font-size: var(--fs-600);
}

.section-button__container {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}
