@use '../abstracts/mixins' as *;

.profile__category {
  padding: 1rem 0;
  border-bottom: 1px solid var(--clr-gray-light);
}

.profile__category-headline {
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.profile__password-data {
  color: var(--clr-gray-dark);

  &:hover {
    color: var(--clr-gray-dark);
  }
}

.profile__email-consent-revert {
  background-color: transparent;
  color: var(--clr-gray-dark);
  text-decoration: underline;
  border: 0;
  padding: 0;
  cursor: pointer;
  margin-top: 0.25rem;
}
