.select {
  display: flex;
  flex-direction: column;

  &--fluid {
    justify-content: stretch;
  }

  &--button-blue {
  }

  &__trigger {
    all: unset;
    display: flex;
    align-items: center;
    justify-items: center;
    border-radius: var(--border-radius);
    border: 1px solid var(--clr-primary);
    background: var(--clr-white);
    gap: 0.5rem;
    font-size: var(--fs-450);
    padding: 1rem;

    & > *:first-child {
      margin-inline-end: auto;
    }

    &:focus {
      outline: 2px solid var(--clr-primary);
    }
  }

  &__icon {
    display: flex;
    align-items: center;
  }

  &__viewport {
    padding: 1rem 0;
  }

  &__content {
    overflow: hidden;
    background-color: var(--clr-white);
    border-radius: var(--border-radius);
    border: 1px solid var(--clr-blue);
  }

  &__item {
    all: unset;
    display: flex;
    align-items: center;
    user-select: none;
    position: relative;
    padding: 0 2rem;
    cursor: pointer;
    font-size: var(--fs-450);

    &[data-disabled] {
      pointer-events: none;
      cursor: not-allowed;

      & > * {
        color: grey;
      }
    }

    &:focus-visible {
      background: var(--clr-light-grey);
    }
  }

  &__item-text {
  }

  &__item-indicator {
    position: absolute;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    left: 0;
  }

  &__scroll-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--clr-white);
    cursor: default;
  }
}

.downshift-select {
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 600px;
  padding-top: 1.5rem;

  &__button {
    color: var(--clr-primary);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: var(--border-radius);
    border: 1px solid var(--clr-blue);
    border-radius: 0.5rem;
    background: var(--clr-white);
    gap: 0.5rem;
    font-size: var(--fs-450);
    padding: 0 1rem;
    height: 3rem;
    overflow: hidden;
    position: relative;

    & svg {
      width: 24px;
      height: 24px;
    }

    & > *:first-child {
      margin-inline-end: auto;
    }

    &:focus {
      box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
    }
  }

  &__button[aria-expanded='true'] &__chevron svg {
    transform: rotate(180deg);
  }

  & .label {
    top: 5px;
  }

  &__button[aria-expanded='true'] {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 0;
  }

  &__chevron {
    display: flex;
    align-items: center;
    background-color: var(--clr-blue);
    position: absolute;
    inset: 0 0 0 auto;
    padding: 0 0.5rem;
  }

  &__chevron svg {
    color: white;
    transition: transform 300ms;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__button[aria-expanded='true'] ~ &__list {
    border: 1px solid var(--clr-blue);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &__list {
    position: absolute;
    background-color: var(--clr-white);
    top: calc(100% - 1.125rem);
    left: 0;
    right: 0;
    z-index: 2;
    list-style: none;
    padding: 0;
    border-radius: 0.5rem;
    overflow: hidden;
  }

  &__list-item {
    padding: 0.5rem 1rem;
    cursor: pointer;
    font-size: var(--fs-450);
  }
}

.downshift-select--form {
  @extend .downshift-select;
  padding-top: 0;
}

.downshift-select__list-item.disabled {
  background-color: var(--clr-gray-nav);
}

.event__options-select .downshift-select {
  padding-top: 0;
}
