@use '../abstracts/mixins' as *;

.blackboard-modal__deletion-content {
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px';
  position: fixed;
  top: 50%;
  left: 50%;
  opacity: 1;
  transform: translate(-50%, -50%);
  min-width: 300px;
  width: auto;
  max-width: 950px;
  max-height: 85vh;
  overflow-y: scroll;
  padding: 2rem 1.5rem;

  @include mq(tiny) {
    min-width: 350px;
  }

  @include mq(small) {
    min-width: 400px;
  }

  @include mq(medium) {
    padding: 3rem 2.5rem;
    min-width: 500px;
  }
}

.blackboard__deletion-confirmation {
  font-weight: 700;
}

.blackboard-form__deletion-button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
  background-color: white;

  & button {
    margin-right: 1.5rem;
    &:last-child {
      margin-right: 0;
    }
  }
}
