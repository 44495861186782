@use '../abstracts/mixins' as *;

.marketplace__images-container {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  justify-items: center;

  @include mq(medium) {
    grid-template-columns: 1fr 1fr;
  }
}

.marketplace__image {
  width: 800px;
  height: 500px;
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
  object-position: center;

  @include mq(medium) {
    height: 300px;
  }
}

.marketplace__short-description {
  font-weight: 700;
  margin-top: 2rem;
}
