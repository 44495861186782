@use '../abstracts/mixins' as *;

.product-categories__container {
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  align-items: center;
  justify-items: center;

  @include mq(smallest) {
    grid-template-columns: 1fr 1fr;
  }

  @include mq(medium) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @include mq(large) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 2rem;
  }
}

.product-category__body {
  background-color: var(--clr-gray-nav);
  border-radius: 0.5rem;
  padding: 1.25rem 1rem;
  height: 180px;
  border: 1px solid var(--clr-gray-light);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  overflow: visible;
}

.product-category {
  padding-top: 100px;
  display: inline-block;
  text-decoration: none;
  width: 100%;

  &:hover {
    .product-category__image-container {
      transform: scale(0.95);
      transition: 0.2s ease-in-out;
    }
  }
}

.product-category__image-container {
  margin-bottom: 0.5rem;
}

.product-category__category-name {
  font-size: var(--fs-500);
  font-weight: 700;
  color: var(--clr-blue);
  hyphens: auto;
  word-break: break-word;
  white-space: wrap;
  min-height: 3rem;
  display: inline-flex;
  align-items: center;
}

.product-category__image {
  width: 200px;
  height: 200px;
  object-position: center;
  object-fit: contain;
}

.products__buttons-container {
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  gap: 0.75rem 1.5rem;
}
