@use './label';
@use '../../abstracts/mixins' as *;

.fieldset {
  border: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: stretch;
  margin: 1.5rem 0 1rem 0;

  &__legend {
    font-size: var(--fs-500);
    margin-bottom: 1rem;
    color: var(--clr-blue);
    font-weight: bold;
  }
}

// disable individual fieldset headlines in radio-groups / checkbox-groups if inside an overall fieldset

.fieldset > .fieldset {
  margin: 0.5rem 0 0.5rem 0;
}

.rating-fieldset__label {
  display: none;
}

.fieldset > .fieldset > .fieldset__legend {
  display: none;
}

.fieldset > .fieldset.rating-fieldset > .fieldset__legend {
  display: none;
}

.fieldset > .fieldset.rating-fieldset {
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  justify-content: flex-start;
  display: flex;
  flex-wrap: wrap-reverse;

  & .rating-fieldset__label {
    display: block;
    width: 100%;

    @include mq(small) {
      width: auto;
    }
  }
}
