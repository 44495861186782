body {
  font-size: var(--fs-400);
  font-family: 'Proxima Nova', sans-serif;
  color: var(--clr-gray-dark);
  hyphens: auto;
  word-break: break-word;
  white-space: wrap;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// This is setting the font-sizes
// based on the ones in the abstract
// folder, go there to make updates

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5 {
  font-weight: 700;
  line-height: 1.2;
}

h1,
.h1 {
  font-size: var(--fs-800);
  color: var(--clr-blue);
  margin: 0;
  margin-bottom: 2rem;
  text-wrap: balance;
}

h2,
.h2 {
  font-size: var(--fs-700);
  color: var(--clr-blue);
  margin: 0;
  text-wrap: balance;
}

h3,
.h3 {
  font-size: var(--fs-600);
  color: var(--clr-blue);
  margin: 0;
  text-wrap: balance;
}

h4,
.h4 {
  font-size: var(--fs-400);
  color: var(--clr-blue);
  margin: 0;
}

h5,
.h5 {
  font-size: var(--fs-300);
  margin: 0;
}

small,
.text-small {
  font-size: var(--fs-200);
}

strong {
  font-weight: 700;
}

a {
  color: var(--clr-blue);

  &:hover,
  &:focus {
    color: var(--clr-blue);
    text-decoration: none;
  }
}

p {
  line-height: 1.5;
  word-break: normal;
  hyphens: none;
}

ul {
  padding-left: 1.25rem;
}

ul li::marker {
  font-size: 12px;
}

ul li {
  padding-left: 0.5rem;
}

ol li {
  padding-left: 0.5rem;
}

.richtext-wrapper {
  a {
    color: var(--clr-blue);
    display: inline-block;
    text-decoration: none;
    position: relative;

    &::after {
      content: '';
      display: block;
      width: 0;
      height: 1px;
      background: var(--clr-blue);
      transition: width 0.3s;
      position: absolute;
      bottom: 1px;
    }

    &:hover::after {
      width: 100%;
      transition: width 0.3s;
    }

    &:hover,
    &:focus {
      color: var(--clr-blue);
    }
  }

  a[href*="//"]:not([href*="mia.werkbank.de"]):not([href*="mia.molkerei-ammerland.de"])
  {
    position: relative;
    margin-right: 1.1rem;
  }

  a[href^='mailto:'] {
    position: relative;
    margin-right: 1.8rem;
  }

  a[href^='tel:'] {
    position: relative;
    margin-right: 1.8rem;
  }

  a[href^='/media/'] {
    position: relative;
    margin-right: 1.8rem;
  }

  a[href*="//"]:not([href*="mia.werkbank.de"]):not([href*="mia.molkerei-ammerland.de"])::before
  {
    position: absolute;
    right: -1.5rem;
    top: calc(50% - 11px);
    content: ' ';
    width: 24px;
    height: 24px;
    background: url('/image-icons/arrow-right-up-blue.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  a[href^='mailto:']::before {
    position: absolute;
    right: -1.5rem;
    top: calc(50% - 9px);
    content: ' ';
    width: 18px;
    height: 18px;
    background: url('/image-icons/mail.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  a[href^='tel:']::before {
    position: absolute;
    right: -1.5rem;
    top: calc(50% - 9px);
    content: ' ';
    width: 18px;
    height: 18px;
    background: url('/image-icons/smartphone-blue.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  a[href^='/media/']::before {
    position: absolute;
    right: -1.5rem;
    top: calc(50% - 9px);
    content: ' ';
    width: 18px;
    height: 18px;
    background: url('/image-icons/file-blue.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

sup {
  font-weight: 400;
  font-size: 0.75rem;
  margin-left: 0.25rem;
}
