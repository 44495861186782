@use '../abstracts/mixins' as *;

.task__container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 2rem;

  @include mq(small) {
    grid-template-columns: 1fr 1fr;
  }

  @include mq(large) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  &--few {
    @include mq(large) {
      grid-template-columns: 1fr 1fr;
    }
  }
}

.task {
  padding: 1.5rem;
  border-radius: 0.5rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  transition: all 0.15s ease-in-out;

  &--rot {
    background-color: rgba(248, 215, 218, 0.3);
    color: var(--clr-gray-dark);
    border: 1px solid var(--clr-red-light);

    & .task__headline {
      color: var(--clr-red-dark);
    }

    &:hover {
      color: var(--clr-gray-dark);
      box-shadow: 0 2px 15px 0 var(--clr-red-light);
    }
  }

  &--gelb {
    background-color: rgba(255, 243, 205, 0.35);
    color: var(--clr-gray-dark);
    border: 1px solid var(--clr-yellow-light);

    & .task__headline {
      color: var(--clr-yellow-dark);
    }

    &:hover {
      color: var(--clr-gray-dark);
      box-shadow: 0 2px 15px 0 var(--clr-yellow-light);
    }
  }

  &--blau {
    background-color: rgba(232, 244, 252, 0.4);
    color: var(--clr-gray-dark);
    border: 1px solid var(--clr-blue-light);

    & .task__headline {
      color: var(--clr-blue);
    }

    &:hover {
      color: var(--clr-gray-dark);
      box-shadow: 0 2px 15px 0 var(--clr-blue-light);
    }
  }

  &--weiss {
    background-color: white;
    border: 1px solid var(--clr-gray-light);
    color: var(--clr-gray-dark);

    & .task__headline {
      color: var(--clr-blue);
    }

    &:hover {
      color: var(--clr-gray-dark);
      box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
    }
  }
}

.task__headline {
  font-size: var(--fs-400);
  line-height: 1.2;
  margin-bottom: 0.5rem;
}

.task__image-container {
  height: 100%;
  display: flex;
  align-items: center;
  width: 30%;
  min-width: 100px;
  margin-left: 0.5rem;
}

.task__image {
  width: 100%;
  object-fit: contain;
  object-position: center;
  height: auto;
  max-height: 50px;
  display: flex;
  justify-content: flex-end;

  & svg {
    max-height: 100px;
  }
}

.task__deadline {
  margin: 0.5rem 0;
  display: flex;
  flex-wrap: wrap;
}

.task__deadline-header {
  margin-right: 0.5rem;
}
