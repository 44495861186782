.nav-mobile {
  display: none;
  position: absolute;
  width: 100%;
  top: 4rem;
  left: 0;
  z-index: 1000;
  background-color: white;
  border-bottom: 1px solid var(--clr-gray);
  height: calc(100vh - 4rem);
  overflow-y: scroll;
}

.nav-mobile.nav--open {
  display: flex;
  flex-direction: column;
}

.nav-mobile__bottom {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.nav-mobile__icon-container {
  display: flex;
  align-items: center;
}

.mobile-nav__icon {
  padding: 0.75rem;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: white;
  display: flex;
  align-items: center;
  font-size: var(--fs-400);
  margin-right: 0.25rem;

  &:last-child {
    margin-right: 0;
  }

  .mobile-nav__icon-svg {
    height: auto;
    width: 20px;
    object-fit: contain;
    object-position: center;

    & g {
      stroke-width: 5 !important;
    }

    &.magnify {
      width: 28px;
    }
  }

  &:hover {
    .mobile-nav__icon-text {
      color: var(--clr-blue-dark);
    }

    .mobile-nav__icon-svg {
      & g {
        stroke: var(--clr-blue-dark) !important;
      }
    }
  }
}

.mobile-nav__icon-text {
  margin-left: 0.25rem;
  white-space: nowrap;
  font-size: 0.875rem;
  font-weight: 700;
  color: var(--clr-gray-dark);
}

.nav-mobile__element,
.nav-mobile__subnav-element {
  background-color: var(--clr-gray-nav);
  border-bottom: 1px solid var(--clr-gray-light);
}

.nav-mobile__element {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-mobile__link,
.nav-mobile__subnav-link {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1rem;
  text-decoration: none;
  font-weight: 600;
  color: var(--clr-gray-dark);

  &.active {
    background: #fff;
    color: var(--clr-blue);
  }
}

.nav-mobile__expand {
  height: 100%;
  width: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  padding: 1rem;
  border-left: 1px solid var(--clr-gray-light);
}

// Subnav
.nav-mobile__subnav-container {
  display: none;
}

.nav-mobile__subnav-container.show {
  display: block;
}

.nav-mobile__subnav-element-container {
  background-color: var(--clr-gray-lightest);
}

.nav-mobile__subnav-element {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-mobile__subnav-link {
  width: 100%;
  height: 100%;
  padding-left: 2.5rem;
  padding-right: 1.5rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: 600;
  color: var(--clr-gray-dark);
}

// Layer2
.nav-mobile__subnav-element-container {
  .nav-mobile__subnav-element-container {
    background-color: var(--clr-gray-light);

    .nav-mobile__subnav-link {
      width: 100%;
      height: 100%;
      padding-left: 5rem;
      padding-right: 1.5rem;
      display: flex;
      align-items: center;
    }
  }
}
