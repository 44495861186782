@use '../abstracts/mixins' as *;
@use '../utilities/container';

.menu-cart__card {
  border-radius: 0.5rem;
  padding: 1.5rem;
  margin-top: 1.5rem;
  background-color: var(--clr-gray-lightest);

  &:first-child {
    margin-top: 0;
  }
}

.menu-cart__card-headline {
  font-size: var(--fs-500);
}

.menu-cart__card-meal {
  margin-top: 1.5rem;
}

.menu-cart__card-upper {
  display: grid;
  grid-template-columns: 8fr 1fr;
  align-items: center;
  grid-gap: 0.5rem;
  justify-content: space-between;
  margin-top: 0.75rem;
  margin-bottom: 1rem;
}

.menu-cart__card-lower {
  display: grid;
  grid-template-columns: 5fr 3fr 3fr;
  align-items: center;
  grid-gap: 0.5rem;
  margin-top: 0.5rem;
  justify-content: space-between;
  margin-top: 0rem;
}

.menu-cart__card-meal-delete-button {
  background-color: transparent;
  border-radius: 0.5rem;
  padding: 0.35rem;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:first-child {
    margin-top: 0;
  }

  & svg {
    width: 1rem;
    height: 1rem;
    object-fit: contain;
    object-position: center;

    & g {
      stroke-width: 5 !important;
      stroke: var(--clr-red-dark) !important;
    }
  }
}

.menu-cart__card-meal-price {
  display: flex;
  justify-content: flex-end;
  padding-right: 0.5rem;
}

.menu-cart__card-meal-amount .input {
  margin: 0;
}

.menu-cart__card-meal-amount .input__element {
  width: 60px;
  height: 30px;
  padding: 0.25rem 0.75rem;
}

.menu-cart__card-footer {
  margin-top: 1rem;
}

.menu-cart__card-daily-total {
  font-weight: 700;
  display: flex;
  justify-content: flex-end;
  padding-right: 0.5rem;
}

.menu-cart__card-total {
  display: grid;
  padding: 1rem;

  & .menu-cart__price-total-pricetag {
    padding-right: 1rem;
  }
}

.menu-cart__card-meal-name {
  display: flex;
  gap: 0 0.25rem;
  flex-wrap: wrap;
}

.menu-cart__card-meal-amount {
  .input .label {
    background-color: transparent;
    top: -1.125rem;
    left: 1px;
  }
}
