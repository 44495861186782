@use '../abstracts/mixins' as *;

.outlet-overview__buttons-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  gap: 0.75rem 1.5rem;
}

.outlet-overview__grid {
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  align-items: center;

  @include mq(smaller) {
    grid-template-columns: 1fr 1fr;
  }

  @include mq(large) {
    grid-gap: 2rem;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.outlet-overview__item {
  background-color: var(--clr-gray-nav);
  border-radius: 0.5rem;
  padding: 1.5rem;
  height: 100%;
  border: 1px solid var(--clr-gray-light);
  width: 100%;
  color: var(--clr-blue);
  text-decoration: none;
}

.outlet-overview__item-wrapper {
  display: flex;
  gap: 1rem;
}

.outlet-overview__item-availability {
  height: 12px;
  width: 12px;
  border-radius: 9999px;

  &.limited {
    background-color: #f9b55c;
  }

  &.unavailable {
    background-color: #dc2f34;
  }
}

.outlet-overview__item-content {
  flex-grow: 1;
}

.outlet-overview__item-title {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 1rem;

  & h3 {
    text-wrap: initial;
  }
}

.outlet-overview__item-description {
  margin-bottom: 1rem;
}

.outlet-overview__item-price {
  font-weight: 700;
  font-size: var(--fs-500);
}

.outlet-overview__item-price-unit {
  font-size: var(--fs-200);
  margin-left: 0.25rem;
}

.outlet-overview__item-image {
  width: 120px;
  height: 80px;
  object-fit: contain;
  object-position: center;
}
