@use '../abstracts/mixins' as *;

.productdetail-page__layout {
  display: flex;
  flex-direction: column;

  @include mq(medium) {
    display: block;
  }
}

.productdetail-page__image-float {
  order: 3;
  @include mq(medium) {
    float: right;
    position: relative;
    z-index: 10;
    margin: 2rem;
  }
}

.productdetail-page__headline {
  margin-bottom: 2rem;
  width: fit-content;
  order: 2;
}

.productdetail-page__content {
  order: 4;
}

.productdetail-page__image-container {
  width: 100%;
  display: flex;
  justify-content: center;

  @include mq(medium) {
    width: 350px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
  }

  @include mq(large) {
    width: 360px;
  }
}

.productdetail-page__image {
  height: 400px;
  width: auto;
  object-fit: contain;
  object-position: center;

  @include mq(medium) {
    width: 100%;
    height: 100%;
    max-height: 500px;
    object-position: center right;
  }
}

// product back
.product-header {
  padding: 0 0 2rem 0;
  order: 1;
}
