@use '../abstracts/mixins' as *;

.marketplace__headline {
  margin-bottom: 2rem;
}

.marketplace__buttons-container {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
  flex-wrap: wrap;
  justify-content: space-between;

  & .button--category {
    margin-top: 0.5rem;
  }
}

.marketplace__buttons-sort {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  & .button--category {
    margin-right: 1.5rem;
  }
}
