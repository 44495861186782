@use '../abstracts/mixins' as *;
@use '../utilities/container';

.sick-report-page {
  margin-bottom: 2rem;

  .form__base {
    padding: 0.5rem 0;
  }

  .form__button {
    padding-top: 2rem;
  }

  .form__header h1 {
    text-transform: capitalize;
    position: relative;
    margin-bottom: 4rem;
  }

  .success__message {
    color: #00b74a;
  }

  .error__message {
    color: #c50000;
  }
  .error__message__email {
    color: #008ac5;
  }
}

.sickreport__content-after {
  margin-top: 2rem;
}
