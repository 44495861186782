@use '../abstracts/mixins' as *;

.blackboard-modal__overlay {
  background-color: rgba(0, 0, 0, 0.44);
  position: fixed;
  width: 100%;
  height: 100%;
  inset: 0;
  z-index: 10000;
  display: grid;
  place-items: center;
  overflow-y: auto;
}

.blackboard-modal__content {
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px';
  position: fixed;
  top: 50%;
  left: 50%;
  opacity: 1;
  transform: translate(-50%, -50%);
  min-width: 300px;
  width: 95%;
  max-height: 85vh;
  overflow-y: scroll;
  padding: 0 1.5rem;

  @include mq(tiny) {
    width: 95%;
    min-width: 350px;
  }

  @include mq(small) {
    max-width: 600px;
    width: 100%;
    min-width: 400px;
  }

  @include mq(medium) {
    max-width: 700px;
    padding: 0 2.5rem;
    width: 100%;
    min-width: 500px;
  }
}

.blackboard__modal-header {
  padding: 2.5rem 0 1rem 0;
  background-color: white;
  position: sticky;
  top: 0;
  display: flex;
  z-index: 40;
  justify-content: space-between;
}

.blackboard-modal__close {
  background-color: transparent;
  border: 0;
  height: auto;
  padding: 0.25rem;
  cursor: pointer;
}

.blackboard-modal__close-icon {
  width: 1.25rem;
  height: 1.25rem;
}

.blackboard-form__button-container {
  display: flex;
  justify-content: flex-end;
  padding: 2rem 0;
  position: sticky;
  bottom: 0;
  background-color: white;
  z-index: 40;
}

.blackboard__image-replace-check {
  display: flex;
  align-items: center;

  & input {
    margin: 0 0.5rem 0 0;
    width: 0.95rem;
    height: 0.95rem;
    border-radius: 0.5rem;
  }
}

.blachboard-form__image-file-inputs {
  display: none;
  &.show {
    display: block;
  }
}

.blackboard-form__edit-heading {
  margin: 2rem 0 0.5rem;
  font-size: var(--fs-300);
  color: var(--clr-gray-dark);
}

.blackboard-form__image {
  width: 140px;
  height: 70px;
  object-fit: cover;
}

.blackboard-form__image-edit-container {
  display: flex;
  align-items: center;

  & button {
    margin-top: 1rem;
  }
}

.blackboard-form__image-container {
  margin-right: 0.75rem;
  margin-top: 1.5rem;

  @include mq(small) {
    margin-right: 1.5rem;
  }
}

.blackboard-form__update-error {
  padding: 1.5rem 1rem;
  background-color: var(--clr-pink);
  color: var(--clr-red-dark);
  text-align: center;
  border-radius: 0.5rem;
  margin: 1rem 0 2.5rem;
}

.blackboard-form__additional-images-button-container {
  margin: 0.5rem 0;
  display: flex;
  justify-content: center;
}

.blackboard-form__addtional-images-button {
  height: 3rem;
  width: 3rem;
  border-radius: 9999px;
  border: 2px solid var(--clr-blue);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  cursor: pointer;
  transition: 0.15s all;

  &:focus,
  &:hover {
    background-color: var(--clr-blue-lightest);
    border-color: var(--clr-blue-dark);
    outline: none;

    & svg {
      stroke: var(--clr-blue-dark);
    }
  }

  & svg {
    stroke: var(--clr-blue);
  }
}
