@use '../abstracts/mixins' as *;
@use '../utilities/container';

.menu-cart__no-items {
  padding-top: 2rem;
}

.menu-cart__price-total {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
}

.menu-cart__price-total-pricetag {
  font-weight: 700;
  margin-left: 1rem;
}

.menu-card__order-buttons {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
  gap: 1.5rem 1rem;
}

.menu-cart__meal-delete-button {
  background-color: transparent;
  border-radius: 0.5rem;
  padding: 0.4rem;
  margin-top: 0.6rem;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:first-child {
    margin-top: 0;
  }

  & svg {
    width: 1rem;
    height: 1rem;
    object-fit: contain;
    object-position: center;

    & g {
      stroke-width: 5 !important;
      stroke: var(--clr-red-dark) !important;
    }
  }
}

.menu-cart__meal-delete-buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
