@use '../../abstracts/mixins' as *;

.svg--center {
  display: flex;
  justify-content: center;
}

.svg__element {
  object-fit: contain;

  // These are special classes designed to work in columns to create offset on images
  @include mq(small) {
    &.mt-16 {
      margin-top: 4rem;
    }

    &.mt-20 {
      margin-top: 5rem;
    }

    &.mt-40 {
      margin-top: 10rem;
    }

    &.mt-neg16 {
      margin-top: -4rem;
    }

    &.mt-neg32 {
      margin-top: -8rem;
    }

    &.mt-neg40 {
      margin-top: -10rem;
    }

    &.mt-neg56 {
      margin-top: -14rem;
    }

    &.mt-neg60 {
      margin-top: -15rem;
    }

    &.ml-72 {
      margin-left: 18rem;
    }
  }
}
