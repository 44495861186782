@use '../abstracts/mixins' as *;
@use '../utilities/container';

.menu-history__grid-header {
  display: grid;
  grid-template-columns: 3fr 10fr;
  grid-gap: 0.5rem;
  font-weight: 700;
  border-bottom: 2px solid var(--clr-gray);
  padding: 1rem 1rem 0.5rem 1rem;

  @include mq(large) {
    grid-template-columns: 3fr 13fr;
  }
}

.menu-history__grid-header-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 3rem;
}

.menu-history__grid-header-category {
  min-width: 100px;
}

.menu-history__grid-header-meal {
  flex-grow: 1;
}

.menu-history__grid-header-price {
  min-width: 90px;
  display: flex;
  justify-content: flex-end;

  @include mq(large) {
    justify-content: flex-end;
  }
}

.menu-history__grid-day {
  display: grid;
  grid-template-columns: 3fr 10fr;
  padding: 1rem;
  grid-gap: 0.5rem;
  border-bottom: 1px solid var(--clr-gray);
  background-color: var(--clr-gray-lightest);

  @include mq(large) {
    grid-template-columns: 3fr 13fr;
  }

  &:nth-child(2n + 1) {
    background-color: white;
  }

  &:last-child {
    border-bottom: 2px solid var(--clr-gray);
  }
}

.menu-history__grid-daily-price {
  font-weight: 700;
  margin-top: 0.5rem;
  display: flex;
  justify-content: flex-end;

  @include mq(large) {
    // Accounting for width of ordered at and gridgap
    margin-right: calc(156px + 3rem);
  }
}

.menu-history__grid-day-date-name {
  margin-bottom: 0.5rem;
}

.menu-history__grid-day-meal-category {
  min-width: 100px;
  white-space: nowrap;
}

.menu-history__grid-day-meal-name {
  display: flex;
  gap: 0 0.25rem;
  flex-wrap: wrap;
  flex-grow: 1;
}

.menu-history__grid-day-meal-price {
  display: flex;
  justify-content: flex-end;
  min-width: 90px;
}

.menu-history__grid-footer {
  display: flex;
  justify-content: flex-end;
  padding: 1rem 1rem 0 1rem;
  gap: 0.5rem;

  @include mq(large) {
    margin-right: calc(156px + 3rem);
  }
}

.menu-history__price-total {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
}

.menu-history__price-total-pricetag {
  font-weight: 700;
  margin-left: 1rem;
}

.menu-history__grid-ordered-at {
  display: none;

  @include mq(large) {
    display: block;
    width: 156px;
    white-space: nowrap;
  }
}

.menu-history__ordered-at-header {
  display: none;

  @include mq(large) {
    display: flex;
    justify-content: center;
    width: 156px;
  }
}

.menu-history__grid-item {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
  gap: 3rem;

  &:first-child {
    margin-top: 0;
  }
}
