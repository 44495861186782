@use '../../abstracts/mixins' as *;
@use '../../utilities/container';

.input {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  position: relative;
  max-width: 600px;

  &__label {
    color: var(--clr-gray-dark);
    background-color: var(--clr-white);
    font-size: 1.125rem;
    user-select: none;
    position: absolute;
    top: -14px;
    left: 20px;
    padding: 0 0.25rem;
  }

  &__element {
    border: 1px solid #bdbdbd;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    height: 3rem;
    // Adjustment accounting for label position;
    padding-left: 20px;
    font-size: 1rem;
    width: 100%;
    cursor: pointer;
    color: var(--clr-gray-dark);
    @include transition(all);

    &:focus {
      border: 2px solid var(--clr-blue);
      outline: none;
    }

    &:focus-visible {
      outline: none;
    }
  }
}

.input--form {
  @extend .input;
  margin-top: 0;
  margin-bottom: 0;
  max-width: 100%;

  &__element {
    max-width: 600px;
  }
}

.hidden-field .input .label {
  display: none;
}

.input input:read-only {
  background-color: var(--clr-gray-lightest);
}

.user-field {
  display: none;
}
