@use '../../abstracts/mixins' as *;

.table-section {
}

.table {
  max-width: 100%;
  overflow: scroll;
  white-space: nowrap;
  border: 0;
  font-size: 1rem;
}

table,
th,
td {
  border: 1px solid var(--clr-gray-light);
  border-collapse: collapse;
}

table tr:nth-child(2n + 1) {
  background: var(--clr-gray-lightest);
}

table td {
  padding: 6px 8px;
  text-align: center;
  border-left: 0;
  border-right: 0;
}

table th {
  text-align: center;
  border-left: 0;
  border-right: 0;
}

.table__caption {
  font-size: var(--fs-500);
  margin-bottom: 0.5rem;
}

table > thead > tr:first-child > th {
  border-bottom: 2px solid #e6e6e6;
  font-weight: 700;
  background-color: white;
  padding: 1rem 8px;
}

table > thead > tr:first-child > th {
  border-top: 0;
}
