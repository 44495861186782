@use '../abstracts/mixins' as *;

.button--primary {
  min-height: 3rem;
  padding: 0.25rem 2rem;
  border-radius: 1.5rem;
  background-color: var(--clr-blue);
  color: var(--clr-white);
  align-items: center;
  cursor: pointer;
  border: 0;
  outline: 0;
  font-size: var(--fs-400);
  display: inline-flex;
  text-decoration: none;

  & svg {
    color: var(--clr-white);
    margin-left: 0.5rem;
  }

  &:disabled {
    background-color: #adadad;
  }

  &:hover,
  &:focus {
    background-color: var(--clr-blue-dark);
    color: var(--clr-white);
  }
}

.button--secondary {
  min-height: 3rem;
  padding: 0.25rem 2rem;
  text-decoration: none;
  border-radius: 1.5rem;
  background-color: var(--clr-blue-lightest);
  color: var(--clr-blue);
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  border: 0;
  outline: 0;
  font-size: var(--fs-400);

  & svg {
    color: var(--clr-blue);
    margin-left: 0.25rem;
  }

  &:hover {
    text-decoration: none;
    background-color: var(--clr-blue-light);
  }
}

.button--category {
  height: 2.5rem;
  padding: 0 1.5rem;
  border-radius: 0.5rem;
  background-color: var(--clr-blue-lightest);
  color: var(--clr-blue);
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  border: 0;
  outline: 0;
  font-size: var(--fs-300);
  font-weight: 700;

  &-icon {
    width: 1rem;
    height: 1rem;
    object-fit: contain;
    object-position: center;
  }

  & svg {
    color: var(--clr-blue);
    margin-left: 0.25rem;
  }

  & svg.button--category-icon {
    margin-left: 0;
    margin-right: 0.25rem;
  }

  &.active {
    background-color: var(--clr-blue);
    color: var(--clr-white);

    & svg {
      color: var(--clr-white);
    }

    & svg.button--category-icon {
      & path,
      circle {
        stroke: white !important;
      }
    }

    &:hover {
      background-color: var(--clr-white);
      background-color: var(--clr-blue-dark);
    }
  }

  &:hover {
    background-color: var(--clr-blue-light);
  }
}

.button__svg {
  display: flex;
  align-items: center;
}

.section-button {
  height: 3rem;
  padding: 0 2rem;
  border-radius: 1.5rem;
  background-color: var(--clr-blue-lightest);
  color: var(--clr-blue);
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  border: 0;
  outline: 0;
  font-size: var(--fs-400);
  text-decoration: none;
  border: 1px solid var(--clr-blue);

  & svg {
    color: var(--clr-blue);
    margin-left: 0.25rem;
  }

  &:hover {
    background-color: var(--clr-blue-light-hover);
  }
}

.section-button__arrow {
  height: 2rem;
  width: auto;

  & path {
    stroke: var(--clr-blue);
  }
}

.button--icon svg {
  margin: 0;
}
