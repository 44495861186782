@use '../abstracts/mixins' as *;
@use '../utilities/container';

.footer {
  background-color: var(--clr-blue);
  color: var(--clr-white);
  padding: 4rem 1rem;
  display: flex;
  justify-content: center;
  font-size: var(--fs-400);

  @include mq(medium) {
    // 240px left padding is used to even up 240px nav-width
    padding: 4rem 0 4rem 240px;
  }
}

.footer__container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: min(100%, 67rem);

  @include mq(small) {
    width: min(100% - 2rem, 69rem);
  }

  @include mq(medium) {
    padding: 0 1rem;
  }
}

.footer__links {
  width: 100%;
  display: flex;
  flex-direction: column;

  @include mq(medium) {
    margin-right: 4rem;
    width: calc(50% - 2rem);
  }

  @include mq(large) {
    margin-right: 3rem;
    width: auto;
  }
}

.footer__link {
  color: var(--clr-white);
  margin-top: 1.5rem;
  font-weight: 700;
  font-size: var(--fs-400);
  text-decoration: none;
  position: relative;
  width: fit-content;

  &:hover,
  &:focus {
    color: var(--clr-white);
  }

  &::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    transition: width 0.3s;
    position: absolute;
    bottom: 1px;
    background: var(--clr-white);
  }

  &:hover::after {
    width: 100%;
    transition: width 0.3s;
  }
}

.footer__link:first-child {
  margin-top: 0;
}

.footer__company-contact {
  width: 100%;
  margin-top: 3rem;

  @include mq(medium) {
    width: calc(50% - 2rem);
    margin-top: 0;
  }

  @include mq(large) {
    margin-right: 3rem;
    width: auto;
  }
}

.footer__factory-contact {
  width: 100%;
  margin-top: 3rem;

  @include mq(large) {
    width: auto;
    margin-top: 0;
  }
}

.footer__company-contact-header {
  margin-bottom: 0.5rem;
  font-weight: 700;
}

.footer__factory-contact-header {
  margin-bottom: 0.5rem;
  font-weight: 700;
}

.footer__company-contact-phone {
  margin-top: 1rem;
}

.footer__factory-contact-phone {
  margin-top: 1rem;
}

.footer__company-contact-mailto {
  display: flex;
  align-items: center;
  color: var(--clr-white);
  text-decoration: none;
  position: relative;
  width: fit-content;

  &:hover,
  &:focus {
    color: var(--clr-white);
  }

  &::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    transition: width 0.3s;
    position: absolute;
    bottom: 2px;
    background: var(--clr-white);
  }

  &:hover::after {
    width: 100%;
    transition: width 0.3s;
  }
}

.footer__factory-contact-flex {
  display: flex;
  align-items: center;
}

.footer__company-contact-flex {
  display: flex;
  align-items: center;
}

.footer__contact-svg {
  height: 18px;
  width: auto;
  object-fit: contain;
  object-position: center;
  margin-left: 0.25rem;

  & g {
    stroke: var(--clr-white) !important;
  }
}

@media print {
  .footer {
    display: none;
  }
}
