@use '../abstracts/mixins' as *;
@use '../utilities/container';

.flex__headline-container {
  display: flex;
  align-items: center;
  grid-gap: 2rem;
  margin-bottom: 2rem;

  & h1 {
    margin-bottom: 0;
  }
}

.flex__headline-svg-container {
  min-width: 60px;
  min-height: 60px;
  display: flex;
  align-items: center;
}

.flex__headline-svg {
  width: 60px;
  height: 60px;
  object-fit: contain;
  object-position: center;
}

.werder-bremen__bonus-code {
  font-weight: 700;
  font-size: 1.75rem;
  color: var(--clr-blue);
  margin-bottom: 1rem;
}
