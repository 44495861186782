@use '../abstracts/mixins' as *;

.profile {
  margin-bottom: 6rem;
}

.profile__headline {
  margin-bottom: 3rem;

  @include mq(md) {
    margin-bottom: 4rem;
  }
}

.profile__logout-container {
  display: flex;
  justify-content: flex-end;
}

.profile__logout-button {
  & svg {
    margin-left: 0.5rem;
    width: 20px;
    height: 20px;

    & g {
      stroke-width: 5 !important;
      stroke: white !important;
    }
  }
}

.profile__email-consent {
  padding: 1.5rem;
  border-radius: 0.5rem;
  margin-top: 1rem;
  background-color: rgba(232, 244, 252, 0.4);
  color: var(--clr-gray-dark);

  & .profile__email-consent-button-container {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
  }
}
