@use '../../abstracts/mixins' as *;

.quote__container {
  position: relative;
  padding: 1.5rem 2.5rem;
  border-radius: 0.5rem;
  width: 100%;
  display: grid;
  align-items: center;
  color: var(--clr-blue);
  background-color: var(--clr-blue-light);
  margin-top: 2rem;
}

.quote__container--image {
  @include mq(medium) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.5rem;
  }
}

.quote__image-container {
  display: none;

  @include mq(medium) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 200px;
  }
}

.quote__image {
  width: 100%;
  height: auto;
  max-height: 200px;
  object-fit: contain;
}

.quote__quotation-mark {
  position: absolute;
  font-size: 10rem;
  line-height: 5rem;
  color: var(--clr-blue);
  top: -1rem;
  left: 30%;
}

.quote__quote {
  font-size: var(--fs-700);
  font-weight: 700;
}

.quote__author {
  margin-top: 1.5rem;
}

// In Columns
.columns__container .quote__quote {
  font-size: var(--fs-400);
}

.columns__container .quote__container--image {
  @include mq(large) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.5rem;
  }
}

.columns__container .quote__image-container {
  display: none;

  @include mq(large) {
    display: block;
  }
}
